<template>
  <div class="page-body">
    <div class="search-container">
      <el-form v-model="searchForm" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="用户ID">
              <el-input v-model="searchForm.userId" placeholder="请输入用户ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重制</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="page-main-container">
      <div class="page-main-inner">
        <el-table v-loading="tableLoading" :data="taskList" :fit="true" stripe border>
          <el-table-column label="ID" prop="id" width="60" align="center" />
          <el-table-column label="创建时间" prop="createTime" width="100" align="center" />
          <el-table-column label="用户ID" prop="userId" width="160" align="center" />
          <el-table-column label="客户端" prop="clientName" width="100" align="center" />
          <el-table-column label="风格" prop="styleName" width="80" align="center" />
          <el-table-column label="提示词" prop="paramPrompt" width="160" align="center">
            <template v-slot="scope">
              <el-tooltip :content="scope.row.paramPrompt" placement="top">
                <div class="prompt-cell">{{ scope.row.paramPrompt }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="taskStatusText" width="100" align="center" />
          <el-table-column label="结果" align="center">
            <template v-slot="scope">
              <div v-if="scope.row.imageList" class="image-list">
                <div v-for="(imageItem, imageIdx) in scope.row.imageList" :key="imageIdx" class="image-item">
                  <img :src="imageItem.previewUrl" class="image" alt="ai draws result image">
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin: 0 auto"
          :current-page="pageParam.page"
          :page-size="pageParam.pageSize"
          :page-count="totalPage"
          layout="prev, pager, next"
          @current-change="onPageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {DrawsApi} from "@/api/draws-api";

export default {
  name: "DrawTaskManager",
  data() {
    return {
      pageParam: {
        page: 1,
        pageSize: 15
      },
      searchForm: {
        userId: null
      },
      totalPage: 0,
      tableLoading: false,
      taskList: []
    }
  },
  mounted() {
    this.getTaskList();
  },
  methods: {
    getTaskList() {
      const params = {
        page: this.pageParam.page,
        pageSize: this.pageParam.pageSize
      }
      if (this.searchForm.userId) {
        params.userId = this.searchForm.userId
      }
      this.tableLoading = true;
      DrawsApi.searchTask(params).then(res => {
        const pageData = res.data
        this.totalPage = pageData.total
        this.taskList = pageData.list
      }).finally(() => {
        this.tableLoading = false;
      })
    },
    onSearch() {
      this.pageParam.page = 1;
      this.getTaskList();
    },
    onReset() {
      this.searchForm.userId = null;
      this.pageParam.page = 1;
      this.getTaskList();
    },
    onPageChange(page) {
      this.pageParam.page = page;
      this.getTaskList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/colors";

.page-body {
  width: 100%;
  height: 100%;
  background-color: $systemBackground;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .search-container {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-sizing: border-box;
    padding-top: 20px;
  }

  .page-main-container {
    width: 100%;
    flex: 1;
    margin-top: 10px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    overflow: scroll;

    .page-main-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.prompt-cell {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.image-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .image-item {
    width: 80px;

    .image {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
  }
}

</style>