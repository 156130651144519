export default {
  props: {
    modelValue: Boolean
  },
  computed: {
    internalVisible: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          this.onShow();
        } else if (oldVal && !newVal) {
          this.onDismiss();
        }
      }
    }
  },

  methods: {

    show() {
      this.internalVisible = true;
    },

    dismiss() {
      this.internalVisible = false;
    },

    onShow() {
      // console.log('on show')
    },

    onDismiss() {
      // console.log('on dismiss')
    }
  }
}