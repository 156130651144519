<template>
  <el-container>
    <el-main style="width: 100%;">
      <div class="switcher-cell">
        <span class="name">AI配音--内容审核开关</span>
        <el-switch v-model="switcherConfigs.ttsCensorEnable" @change="_onTtsCensorEnableChange"/>
      </div>
      <div class="switcher-cell">
        <span class="name">ChatGpt--内容审核开关</span>
        <el-switch v-model="switcherConfigs.gptCensorEnable" @change="_onGptCensorEnableChange"/>
      </div>
    </el-main>
  </el-container>
</template>

<script>

import {CommonSwitcherApis} from "@/api/common-switcher-apis";
import {TtsApis} from "@/api/tts-apis";
import {GptApis} from "@/api/gpt-apis";

export default {
  /**
   * 常用开关配置
   */
  name: "CommonSwitcherPage",
  data() {
    return {
      switcherConfigs: {
        ttsCensorEnable: false,
        gptCensorEnable: false,
      }
    }
  },

  mounted() {
    this._onInit();
  },

  methods: {

    _onInit() {
      CommonSwitcherApis.getSwitchConfigs().then(res => {
        this.switcherConfigs = res.data;
      })
    },

    _onTtsCensorEnableChange(newValue) {
      const params = {
        key: 'tts_censor_enable',
        value: newValue
      }
      CommonSwitcherApis.updateSwitcherConfig(params).then(_ => {
        this.switcherConfigs.ttsCensorEnable = newValue;
      }).catch(_ => {
        this.switcherConfigs.ttsCensorEnable = !newValue;
      })
    },

    _onGptCensorEnableChange(newValue) {
      const params = {
        key: 'gpt_censor_enable',
        value: newValue
      }
      CommonSwitcherApis.updateSwitcherConfig(params).then(_ => {
        this.switcherConfigs.gptCensorEnable = newValue;
      }).catch(_ => {
        this.switcherConfigs.gptCensorEnable = !newValue;
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.switcher-cell {
  height: 44px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  padding-left: 10px;

  .name {
    width: 300px;
  }
}

</style>