import {DateUtils} from "@/utils/DateUtils";
import {UserDefaults} from "@/data/UserDefaults";

const KEY_USER_INFO = "qx_user_info";
export const NOTIFICATION_LOGIN_SUCCESS = "qx_login_success";
export const NOTIFICATION_LOGIN_OUT = "qx_login_out";


class AccountManagerCls {

    static getInstance() {
        if (!this.instance) {
            this.instance = new AccountManagerCls();
        }
        return this.instance;
    }

    constructor() {
        this.userInfo = UserDefaults.getObject(KEY_USER_INFO, null);
        if (this.isTokenExpired()) {
            this.userInfo = null;
            UserDefaults.removeItem(KEY_USER_INFO);
        }
    }

    parseExpireFromToken() {
        let token = this.token();
        if (token) {
            let strings = token.split(".");//通过split()方法将token转为字符串数组
            let decoded = strings[1].replace(/-/g, "+").replace(/_/g, "/");
            let json = JSON.parse(decodeURIComponent(window.atob(decoded)));
            return json.exp * 1000;
        }
        return 0;
    }

    setLoginSuccess(userInfo) {
        /**
         userInfo: {
            String token;
            String username;
            String avatar;
            int vipLevel;
            int unread;
         }
         */
        this.userInfo = userInfo;
        this.userInfo.expire = this.parseExpireFromToken();
        UserDefaults.setToken(userInfo.token);
        UserDefaults.setObject(KEY_USER_INFO, this.userInfo);
        // NotificationCenter.notify(NOTIFICATION_LOGIN_SUCCESS, this.userInfo);
    }

    setLoginOut() {
        this.userInfo = null;
        UserDefaults.removeItem(KEY_USER_INFO);
        // NotificationCenter.notify(NOTIFICATION_LOGIN_OUT)
    }

    // ===========================================
    //      Getter
    // ===========================================

    isLogin() {
        return this.userInfo !== null && this.userInfo !== undefined;
    }

    token() {
        return this.userInfo != null ? this.userInfo.token : null;
    }

    expire() {
        if (this.userInfo && this.userInfo.expire) {
            return this.userInfo.expire;
            // return this.parseExpireFromToken();
        }
        return 0;
    }

    isTokenExpired() {
        return DateUtils.currentTimeInMillis() > this.expire();
    }

    username() {
        if (!this.userInfo) {
            return null;
        }
        return this.userInfo.username;
    }

    avatar() {
        if (!this.userInfo) {
            return null;
        }
        return this.userInfo.avatar;
    }
}

export const AccountManager = AccountManagerCls.getInstance();
