<template>
    <div class="sidebar-container">
        <el-scrollbar wrap-class="wrap-scrollbar">
            <el-menu class="el-menu-container"
                     router
                     :default-active="$route.path"
                     background-color="#304156"
                     text-color="#bfcbd9"
                     active-text-color="#409EFF">

                <div v-for="(item, i) in routers" :key="i">
                    <!-- 如果有子菜单 -->
                    <el-sub-menu v-if="item.children" :index="item.path">
                        <template #title>
                            <svg-icon v-if="item.meta.icon" :icon-class="item.meta.icon"/>
                            <svg-icon v-else icon-class="menu"/>
                            <span>{{ item.meta.title }}</span>
                        </template>

                        <template v-for="(child, childIndex) in item.children" :key="childIndex">
                          <router-link :to="child.path">
                            <el-menu-item v-if="!child.hidden"
                                          :key="childIndex"
                                          :index="child.path">
                              <template #title>
                                <svg-icon v-if="child.meta.icon" :icon-class="child.meta.icon"/>
                                <svg-icon v-else icon-class="menu"/>
                                <span>{{ child.meta.title }}</span>
                              </template>
                            </el-menu-item>
                          </router-link>
                        </template>

                    </el-sub-menu>

                  <router-link v-else :to="item.path">
                    <el-menu-item :index="item.path">
                      <template #title>
                        <svg-icon v-if="item.meta.icon" :icon-class="item.meta.icon"/>
                        <svg-icon v-else icon-class="menu"/>
                        <span>{{ item.meta.title }}</span>
                      </template>
                    </el-menu-item>
                  </router-link>
                </div>

            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>

import {Menu as IconMenu, Setting, HomeFilled, Avatar, UserFilled} from "@element-plus/icons-vue"
import SvgIcon from "@/components/SvgIcon";

export default {
    name: "AdminSideBar",
    components: {
        SvgIcon,
        IconMenu, Setting, HomeFilled, Avatar, UserFilled
    },
    computed: {
        routers() {
            return this.$store.getters.routers
        },
    },
    mounted() {
        // 调用store.router模块中的getRouters方法来初始化路由表
        this.$store.dispatch("routers/getRouters");
        // console.log("menu routers: " + this.routers)
    }
}
</script>

<style lang="scss" scoped>
$side-bar-width: 200px;


.sidebar-container {
    width: $side-bar-width !important;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    position: fixed;

  a {
    text-decoration: none;
  }

    .el-scrollbar {
        height: 100%;
    }

    .el-menu-container {
        height: 100vh;
        background-color: $menuBg;
    }

    .el-submenu {
        min-width: $side-bar-width;
    }

    .el-menu-item {
        padding: 0;
        min-width: $side-bar-width;
        //background-color: $subMenuBg !important;
        //&:hover {
        //    background-color: $menuHover !important;
        //}
    }

    .svg-icon {
        margin-right: 16px;
    }

    .el-menu--collapse .el-menu .el-submenu {
        min-width: 180px !important;
    }
}

</style>