<template>
  <div v-loading="apiLoading" class="admin-page">
    <div class="filter-container">
      <el-button type="success" @click="onSyncAzureVoiceClick">同步Azure</el-button>
      <el-select class="ml-10" v-model="curRegion" placeholder="输入节点地区">
        <el-option v-for="item in regionList" :key="item.key" :label="item.name" :value="item.key" />
      </el-select>
      <div class="locale-wrap">
        <el-input v-model="curLocale" placeholder="输入Locale" style="width: 230px" clearable></el-input>
        <el-button class="ml-10" type="primary" @click="onSearchClick">查询</el-button>
      </div>
    </div>
    <el-table class="table" :data="voiceList" stripe border>
      <el-table-column label="序号" type="index" width="60" align="center" />
      <el-table-column label="LocalName" prop="localName" min-width="100" align="center" />
      <el-table-column label="DisplayName" prop="displayName" min-width="120" align="center" />
      <el-table-column label="ShortName" prop="shortName" min-width="100" align="center" />
      <el-table-column label="Gender" prop="gender" min-width="100" align="center" />
      <el-table-column label="Locale" prop="locale" min-width="100" align="center" />
      <el-table-column label="LocaleName" prop="localeName" min-width="120" align="center" />
      <el-table-column label="SecondaryLocaleList" min-width="150" align="center">
        <template v-slot="scope">
          <el-button
            v-if="scope.row.secondaryLocaleList"
            text
            type="primary"
            @click="onReviewText(scope.row.secondaryLocaleList)">
            查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="StyleList" min-width="120" align="center">
        <template v-slot="scope">
          <el-button
            v-if="scope.row.styleList"
            text
            type="primary"
            @click="onReviewText(scope.row.styleList)">
            查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="RolePlayList" prop="roleList" min-width="120" align="center"></el-table-column>
      <el-table-column label="SampleRateHertz" prop="sampleRate" min-width="200" align="center" />
      <el-table-column label="是否添加" min-width="200" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.used" style="color: #30C690">已添加</span>
          <span v-else style="color: #fa6239">未添加</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120" align="center" fixed="right">
        <template v-slot="scope">
          <el-button type="primary" text @click="onItemAddClick(scope.row)">添加</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="totalPage > 0" v-model="page" :page-size="pageSize" :page-count="totalPage" @current-change="onPageChange"></el-pagination>
  </div>
  <el-dialog v-model="previewDialogVisible" title="查看" width="50%">
    <div>{{ previewText }}</div>
  </el-dialog>
  <voice-add-dialog ref="addVoiceDialog" v-model="addDialogVisible"/>
</template>

<script>
import {DubbingApis} from "@/api/dubbing";
import VoiceAddDialog from "@/views/dubbing/voice/VoiceAddDialog.vue";

export default {
  name: "AzureVoiceManager",
  components: {VoiceAddDialog},
  data() {
    return {
      apiLoading: false,
      curLocale: 'zh',
      curRegion: 'southeastasia',
      regionList: [
        {
          key: 'southeastasia',
          name: '东南亚'
        },
        {
          key: 'japan',
          name: '日本'
        },
        {
          key: 'chinaeast1',
          name: '中国东部1'
        },
        {
          key: 'chinanorth2',
          name: '中国北部2'
        }
      ],
      voiceList: [],
      displayList: [],
      page: 1,
      pageSize: 20,
      totalPage: 0,
      previewDialogVisible: false,
      previewText: null,
      addDialogVisible: false
    }
  },
  mounted() {
    this.getAzureVoiceList()
  },
  methods: {
    onSyncAzureVoiceClick() {
      const params = {
        region: this.curRegion
      };
      this.apiLoading = true;
      DubbingApis.syncAzureVoice(params).then(_ => {
        this.$message.success('同步成功')
      }).finally(() => {
        this.apiLoading = false;
      })
    },
    getAzureVoiceList() {
      const params = {
        page: this.page,
        pageSize: this.pageSize,
        region: this.curRegion
      }
      if (this.curLocale && this.curLocale !== 'all') {
        params.locale = this.curLocale
      }
      this.apiLoading = true
      DubbingApis.searchAzureVoiceList(params).then(res => {
        const pageData = res.data;
        this.voiceList = pageData.list;
        this.totalPage = pageData.total;
      }).finally(() => {
        this.apiLoading = false;
      })
    },
    onPageChange(val) {
      this.page = val;
      this.getAzureVoiceList()
    },
    onSearchClick() {
      this.page = 1;
      this.getAzureVoiceList();
    },
    onReviewText(text) {
      this.previewText = text;
      this.previewDialogVisible = true;
    },
    onItemAddClick(itemData) {
      this.$refs.addVoiceDialog.showWithAzureVoice(itemData)
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;

  .filter-container {
    width: 100%;
    display: flex;
    align-items: center;

    .locale-wrap {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }

  .table {
    width: 100%;
    margin-top: 20px;
  }
}
</style>