import {Apis} from "@/api/Apis";

export const CommonSwitcherApis = {

    getSwitchConfigs() {
        return Apis.get("/admin/settings/switcher/configs")
    },

    updateSwitcherConfig(params) {
        return Apis.get('/admin/settings/switcher/update', params)
    }
}